
.faq-mark {
    width: 24px;
    min-width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 12px;
    background-color: var(--v-grey-lighten3);
}
@media (min-width: 1024px) {
    .faq-mark {
        width: 40px;
        min-width: 40px;
        height: 40px;
        margin-right: 14px;
    }
}

::v-deep {
    .v-expansion-panel-header,
    .v-expansion-panel-content__wrap {
        padding: 16px 12px;
    }
    .v-expansion-panel-content__wrap {
        display: flex;
        align-items: center;
        background: var(--v-grey-lighten5);
    }
    .v-expansion-panel--active {
        .faq-mark {
            background-color: var(--v-primary-base);
        }
    }
    .v-expansion-panel--active + .v-expansion-panel,
    .v-expansion-panel--active:not(:first-child) {
        margin-top: 0;
    }
    .v-expansion-panel-header__icon {
        .v-icon {
            color: var(--v-grey-base) !important;
        }
    }
}
