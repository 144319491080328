
.highway {
    aspect-ratio: 720/362;
    position: relative;
    padding-top: 24px;
    &__bg {
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__logo {
        max-width: 240px;
        margin: auto;
    }
}
.aspiration {
    width: calc(100% - var(--container-gutter) * 2);
    max-width: 1076px;
    padding: 30px 20px;
    margin: auto;
    text-align: center;
    transform: translateY(-100px);
}
@media (min-width: 576px) {
    .aspiration {
        transform: translateY(-90%);
    }
}
@media (min-width: 768px) {
    .highway {
        aspect-ratio: initial;
        height: 290px;
        padding-top: 50px;
        &__logo {
            max-width: 270px;
        }
    }
}
@media (min-width: 1200px) {
    .aspiration {
        padding: 60px 0;
    }
    .aspiration {
        transform: translateY(-50%);
    }
}

.b2c {
    margin-bottom: 60px;
    &:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    &__list {
        > li {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }

            padding-left: 34px;
            position: relative;
            &::before {
                content: "";
                width: 24px;
                height: 24px;
                background: url(/images/sub/business/about/check-circle.png) no-repeat center;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    .v-image {
        margin-bottom: 28px;
    }
}
@media (min-width: 768px) {
    .b2c {
        padding-bottom: 60px;
        border-bottom: 1px solid var(--border-color);
        .v-image {
            margin-bottom: 0;
        }
    }
}

.b2b {
    margin-top: 40px;
    &__tit {
        text-align: center;
        margin-bottom: 30px;
    }
}
@media (min-width: 1024px) {
    .b2b {
        margin-top: 90px;
        &__tit {
            margin-bottom: 70px;
        }
    }
}
