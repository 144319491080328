
.sub-visual {
    position: relative;
    justify-content: center;
    align-items: center;
    &__bg {
        background: center no-repeat;
        background-size: cover;
        width: calc(100% + 2px);
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
}
::v-deep() {
    .v-breadcrumbs {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
@media (min-width: 1200px) {
    .sub-visual {
        height: 200px;
    }
    ::v-deep() {
        .v-breadcrumbs {
            justify-content: flex-end;
        }
    }
}
